import * as echarts from 'echarts/core';
import {
    GridComponent
} from 'echarts/components';
import {
    BarChart
} from 'echarts/charts';
import {
    CanvasRenderer
} from 'echarts/renderers';

echarts.use(
    [GridComponent, BarChart, CanvasRenderer]
);

var proceduresDom = document.getElementById('procedures');
var paymentsDom = document.getElementById('payments');

if (proceduresDom != null) {
    var proceduresChart = echarts.init(proceduresDom);

    var proceduresOption = {
        xAxis: {
            type: 'value'
        },
        yAxis: {
            type: 'category',
            data: xAxisData
        },
        grid: {
            left: '1%',
            right: '1%',
            bottom: '3%',
            containLabel: true
        },
        series: [{
            data: seriesData,
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
                color: 'rgba(180, 180, 180, 0.2)'
            }
        }]
    };
    proceduresOption && proceduresChart.setOption(proceduresOption);
}

// if (paymentsDom != null) {
//     var paymentsChart = echarts.init(paymentsDom);

//     var paymentsOption ={
//         tooltip: {
//             trigger: 'axis',
//             axisPointer: {            // Use axis to trigger tooltip
//                 type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
//             }
//         },
//         legend: {
//             data: ['Novo Nordisk', 'Teva Pharmaceuticals', 'Sanofi', 'Food and Beverage ', 'Consulting']
//         },
//         grid: {
//             left: '3%',
//             right: '4%',
//             bottom: '3%',
//             containLabel: true
//         },
//         xAxis: {
//             type: 'category'
//         },
//         yAxis: {
//             type: 'value'
//         },
//         series: [
//             {
//                 name: 'Novo Nordisk',
//                 type: 'bar',
//                 stack: 'drug',
//                 label: {
//                     show: true
//                 },
//                 emphasis: {
//                     focus: 'series'
//                 },
//                 data: [101]
//             },
//             {
//                 name: 'Teva Pharmaceuticals',
//                 type: 'bar',
//                 stack: 'drug',
//                 label: {
//                     show: true
//                 },
//                 emphasis: {
//                     focus: 'series'
//                 },
//                 data: [120]
//             },
//             {
//                 name: 'Sanofi',
//                 type: 'bar',
//                 stack: 'drug',
//                 label: {
//                     show: true
//                 },
//                 emphasis: {
//                     focus: 'series'
//                 },
//                 data: [220, 182, 191, 234, 290, 330, 310]
//             },
//             {
//                 name: 'Food and beverage',
//                 type: 'bar',
//                 stack: 'other',
//                 label: {
//                     show: true
//                 },
//                 emphasis: {
//                     focus: 'series'
//                 },
//                 data: [150]
//             },
//             {
//                 name: 'Consulting',
//                 type: 'bar',
//                 stack: 'other',
//                 label: {
//                     show: true
//                 },
//                 emphasis: {
//                     focus: 'series'
//                 },
//                 data: [820]
//             }
//         ]
//     };

//     paymentsOption && paymentsChart.setOption(paymentsOption);
// }
